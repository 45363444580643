@import '_globals';

// BUTTON BASE
// ---------------------------------------------------------------------------------------------------------------------
section button,
[type="submit"],
[type="reset"],
.button,
.link {
    appearance: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-family: 'Din Pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    transition: background-color .2s, color .2s;
}

.button,
[type="submit"] {
    .mx-button();

    // colors
    &.blue {
        .mx-button-blue() !important;
    }

    &.lightblue {
        .mx-button-lightblue() !important;
    }

    &.teal {
        .mx-button-teal() !important;
    }

    &.turquoise {
        .mx-button-turquoise() !important;
    }

    &.red,
    &.buy {
        .mx-button-red() !important;
    }

    &.orange {
        .mx-button-orange() !important;
    }

    &.green {
        .mx-button-green() !important;
    }

    &.gray {
        .mx-button-gray() !important;
    }

    &.white {
        .mx-button-white() !important;
    }

    // mutlilingual button
    &.multilingual {
        overflow: hidden;
        vertical-align: top;
        padding-right: 0;
        cursor: auto;

        &:before {
            margin-right: 5px;
        }

        > a {
            padding: 10px;
            border-left: #82b0d4 1px solid;
            color: #fff;
            -webkit-transition: background-color .4s;
            -o-transition: background-color .4s;
            transition: background-color .4s;

            &:hover {
                background-color: #7fafd5;
            }

            &:first-child {
                margin-left: 10px;
            }
        }
    }
}

// LINKS
// ---------------------------------------------------------------------------------------------------------------------
.link {
    background: none;
    color: @c-blue-200;
    font-size: 15px;
    font-weight: 500;

    &:hover,
    &:focus {
        color: tint(@c-blue-200, 40%);
    }

    &.darkgray {
        color: @c-darkgray-400;

        &:hover,
        &:focus {
            color: tint(@c-darkgray-400, 40%);
        }
    }

    &.white {
        color: @c-white;

        &:hover,
        &:focus {
            color: @c-white;
            text-decoration: underline;
        }
    }
}

h4 .link {
    font-size: inherit;
}

// ICONS
// ---------------------------------------------------------------------------------------------------------------------
.button,
.link,
[type="submit"],
[type="reset"] {
    &:before,
    &:after {
        background-size: contain;
        color: inherit;
        display: inline-block;
        font-family: 'Iconfont' !important;
        line-height: 24px;
        height: 24px;
        vertical-align: top;
    }

    &:before,
    img:first-child {
        margin-right: 8px;
    }

    &:after,
    img:last-child {
        margin-left: 8px;
    }

    &.intern:before {
        content: '';
        width: 8px;
    }

    &.mail:before {
        content: 'm';
        font-size: 14px;
    }

    &.download:before {
        content: 'd';
    }

    &.read:before,
    &.pdf:before {
        content: '';
        width: 15px;
    }

    &.bookmark-add:before,
    &.bookmark-remove:before {
        content: '';
        width: 13px;
    }

    &.back:before {
        content: '<';
    }

    &.forward:after,
    &.further:after {
        content: '>';
    }

    &.plus:before {
        content: '+';
    }

    &.minus:before {
        content: 'h';
    }

    &.print:before,
    &.print-view:before {
        content: 'P';
        font-size: 20px;
    }

    &.toggle-info:before,
    &.expand:before {
        content: '=';
    }

    &.reset,
    &[type="reset"] {
        &:before {
            content: 'i';
        }
    }
}

// LINK ICONS
.link {
    &.intern:before {
        background: url('img/ico-arrow-right.svg') no-repeat center center;
    }

    &.read:before,
    &.pdf:before {
        background: url('img/ico-read.svg') no-repeat center center;
    }

    &.webinar:before {
        content: '';
        background: url('img/ico-webinar.svg') no-repeat center center;
        width: 22px;
        margin-right: 5px;
    }

    &.bookmark-add:before {
        background: url('img/ico-bookmark-add.svg') no-repeat center center;
    }

    &.bookmark-remove:before {
        background: url('img/ico-bookmark-remove.svg') no-repeat center center;
    }

    &.german:before,
    &.english:before {
        content: '';
        width: 25px;
        height: 24px;
    }

    &.german:before {
        background: url('img/icon-flagge-sprache-deutsch.svg') no-repeat center center;
    }

    &.english:before {
        background: url('img/icon-flagge-sprache-englisch.svg') no-repeat center center;
    }

    &.darkgray {
        &.read:before {
            background: url('img/ico-read-darkgray.svg') no-repeat center center;
        }
    }

    &.white {
        &.read:before {
            background: url('img/ico-read-white.svg') no-repeat center center;
        }
    }
}

// FILLED BUTTON ICONS
.button {
    &.intern:before {
        background: url('img/ico-arrow-right-white.svg') no-repeat center center;
    }

    &.read:before,
    &.pdf:before {
        background: url('img/ico-read-white.svg') no-repeat center center;
    }

    &.bookmark-add:before {
        background: url('img/ico-bookmark-add-white.svg') no-repeat center center;
    }

    &.bookmark-remove:before {
        background: url('img/ico-bookmark-remove-white.svg') no-repeat center center;
    }
}

// AREA COLOR BLUE
// ---------------------------------------------------------------------------------------------------------------------
// uses the default color

// AREA COLOR LIGHTBLUE
// ---------------------------------------------------------------------------------------------------------------------
.area-color-lightblue {
    .button,
    [type="submit"] {
        .mx-button-lightblue();
    }
}

// AREA COLOR TEAL
// ---------------------------------------------------------------------------------------------------------------------
.area-color-teal {
    .button,
    [type="submit"] {
        .mx-button-teal();
    }
}

// AREA COLOR TURQUOISE
// ---------------------------------------------------------------------------------------------------------------------
.area-color-turquoise {
    .button,
    [type="submit"] {
        .mx-button-turquoise();
    }
}

// OLD PDF LINK
// ---------------------------------------------------------------------------------------------------------------------
// TODO: should be removed in the future and replaced with the classes "link read" or "link pdf"
a:not(.link).pdf {
    display: block;
    font-family: 'Din Pro', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    transition: color .2s;

    &:hover,
    &:focus {
        color: tint(@c-blue-200, 40%);
    }

    &:before {
        background: url('img/ico-read.svg') no-repeat center center;
        background-size: contain;
        color: inherit;
        content: '';
        display: inline-block;
        font-family: 'Iconfont' !important;
        height: 24px;
        line-height: 24px;
        margin-right: 8px;
        vertical-align: top;
        width: 15px;
    }
}

// ***********************************************************************
// MEDIA QUERY: smartphone portrait
// ***********************************************************************
@media screen and (max-width: 479px){
    .button,
    [type="submit"] {
        width: 100%;
        display: block;

        + & {
            margin-top: 10px;
        }
    }
}