section button,
[type="submit"],
[type="reset"],
.button,
.link {
  appearance: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: 'Din Pro', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  transition: background-color 0.2s, color 0.2s;
}
.button,
[type="submit"] {
  background-color: #0080bc;
  color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #FFF;
  font-weight: 500;
  padding: 6px 25px;
  user-select: none;
  text-align: center;
  word-break: keep-all;
}
.button:hover,
[type="submit"]:hover,
.button:focus,
[type="submit"]:focus {
  background-color: #4da6d0;
  color: #ffffff;
}
.button.inactive,
[type="submit"].inactive,
.button[disabled],
[type="submit"][disabled] {
  background-color: #ddd7d7 !important;
  color: #424242 !important;
  cursor: default;
  pointer-events: none;
}
.button.inactive:before,
[type="submit"].inactive:before,
.button[disabled]:before,
[type="submit"][disabled]:before {
  display: none;
}
.button:hover,
[type="submit"]:hover,
.button:focus,
[type="submit"]:focus {
  text-decoration: none;
}
.button.blue,
[type="submit"].blue {
  background-color: #0080bc !important;
  color: #ffffff !important;
}
.button.blue:hover,
[type="submit"].blue:hover,
.button.blue:focus,
[type="submit"].blue:focus {
  background-color: #4da6d0 !important;
  color: #ffffff !important;
}
.button.lightblue,
[type="submit"].lightblue {
  background-color: #62B8E8 !important;
  color: #ffffff !important;
}
.button.lightblue:hover,
[type="submit"].lightblue:hover,
.button.lightblue:focus,
[type="submit"].lightblue:focus {
  background-color: #539cc5 !important;
  color: #ffffff !important;
}
.button.teal,
[type="submit"].teal {
  background-color: #668483 !important;
  color: #ffffff !important;
}
.button.teal:hover,
[type="submit"].teal:hover,
.button.teal:focus,
[type="submit"].teal:focus {
  background-color: #94a9a8 !important;
  color: #ffffff !important;
}
.button.turquoise,
[type="submit"].turquoise {
  background-color: #38B5AA !important;
  color: #ffffff !important;
}
.button.turquoise:hover,
[type="submit"].turquoise:hover,
.button.turquoise:focus,
[type="submit"].turquoise:focus {
  background-color: #74cbc4 !important;
  color: #ffffff !important;
}
.button.red,
[type="submit"].red,
.button.buy,
[type="submit"].buy {
  background-color: #bc1414 !important;
  color: #ffffff !important;
}
.button.red:hover,
[type="submit"].red:hover,
.button.buy:hover,
[type="submit"].buy:hover,
.button.red:focus,
[type="submit"].red:focus,
.button.buy:focus,
[type="submit"].buy:focus {
  background-color: #d05b5b !important;
  color: #ffffff !important;
}
.button.orange,
[type="submit"].orange {
  background-color: #f2801f !important;
  color: #ffffff !important;
}
.button.orange:hover,
[type="submit"].orange:hover,
.button.orange:focus,
[type="submit"].orange:focus {
  background-color: #ce6d1a !important;
  color: #ffffff !important;
}
.button.green,
[type="submit"].green {
  background-color: #2d8b1c !important;
  color: #ffffff !important;
}
.button.green:hover,
[type="submit"].green:hover,
.button.green:focus,
[type="submit"].green:focus {
  background-color: #6cae60 !important;
  color: #ffffff !important;
}
.button.gray,
[type="submit"].gray {
  background-color: #c0c0c0 !important;
  color: #000000 !important;
}
.button.gray:hover,
[type="submit"].gray:hover,
.button.gray:focus,
[type="submit"].gray:focus {
  background-color: #d3d3d3 !important;
  color: #000000 !important;
}
.button.white,
[type="submit"].white {
  background-color: #ffffff !important;
  color: #0080bc !important;
}
.button.white:hover,
[type="submit"].white:hover,
.button.white:focus,
[type="submit"].white:focus {
  background-color: #4da6d0 !important;
  color: #ffffff !important;
}
.button.white.inactive,
[type="submit"].white.inactive,
.button.white[disabled],
[type="submit"].white[disabled] {
  cursor: default !important;
  opacity: 0.6 !important;
  pointer-events: none !important;
}
.button.white.inactive:before,
[type="submit"].white.inactive:before,
.button.white[disabled]:before,
[type="submit"].white[disabled]:before {
  display: none !important;
}
.button.multilingual,
[type="submit"].multilingual {
  overflow: hidden;
  vertical-align: top;
  padding-right: 0;
  cursor: auto;
}
.button.multilingual:before,
[type="submit"].multilingual:before {
  margin-right: 5px;
}
.button.multilingual > a,
[type="submit"].multilingual > a {
  padding: 10px;
  border-left: #82b0d4 1px solid;
  color: #fff;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.button.multilingual > a:hover,
[type="submit"].multilingual > a:hover {
  background-color: #7fafd5;
}
.button.multilingual > a:first-child,
[type="submit"].multilingual > a:first-child {
  margin-left: 10px;
}
.link {
  background: none;
  color: #0080bc;
  font-size: 15px;
  font-weight: 500;
}
.link:hover,
.link:focus {
  color: #66b3d7;
}
.link.darkgray {
  color: #424242;
}
.link.darkgray:hover,
.link.darkgray:focus {
  color: #8e8e8e;
}
.link.white {
  color: #ffffff;
}
.link.white:hover,
.link.white:focus {
  color: #ffffff;
  text-decoration: underline;
}
h4 .link {
  font-size: inherit;
}
.button:before,
.link:before,
[type="submit"]:before,
[type="reset"]:before,
.button:after,
.link:after,
[type="submit"]:after,
[type="reset"]:after {
  background-size: contain;
  color: inherit;
  display: inline-block;
  font-family: 'Iconfont' !important;
  line-height: 24px;
  height: 24px;
  vertical-align: top;
}
.button:before,
.link:before,
[type="submit"]:before,
[type="reset"]:before,
.button img:first-child,
.link img:first-child,
[type="submit"] img:first-child,
[type="reset"] img:first-child {
  margin-right: 8px;
}
.button:after,
.link:after,
[type="submit"]:after,
[type="reset"]:after,
.button img:last-child,
.link img:last-child,
[type="submit"] img:last-child,
[type="reset"] img:last-child {
  margin-left: 8px;
}
.button.intern:before,
.link.intern:before,
[type="submit"].intern:before,
[type="reset"].intern:before {
  content: '';
  width: 8px;
}
.button.mail:before,
.link.mail:before,
[type="submit"].mail:before,
[type="reset"].mail:before {
  content: 'm';
  font-size: 14px;
}
.button.download:before,
.link.download:before,
[type="submit"].download:before,
[type="reset"].download:before {
  content: 'd';
}
.button.read:before,
.link.read:before,
[type="submit"].read:before,
[type="reset"].read:before,
.button.pdf:before,
.link.pdf:before,
[type="submit"].pdf:before,
[type="reset"].pdf:before {
  content: '';
  width: 15px;
}
.button.bookmark-add:before,
.link.bookmark-add:before,
[type="submit"].bookmark-add:before,
[type="reset"].bookmark-add:before,
.button.bookmark-remove:before,
.link.bookmark-remove:before,
[type="submit"].bookmark-remove:before,
[type="reset"].bookmark-remove:before {
  content: '';
  width: 13px;
}
.button.back:before,
.link.back:before,
[type="submit"].back:before,
[type="reset"].back:before {
  content: '<';
}
.button.forward:after,
.link.forward:after,
[type="submit"].forward:after,
[type="reset"].forward:after,
.button.further:after,
.link.further:after,
[type="submit"].further:after,
[type="reset"].further:after {
  content: '>';
}
.button.plus:before,
.link.plus:before,
[type="submit"].plus:before,
[type="reset"].plus:before {
  content: '+';
}
.button.minus:before,
.link.minus:before,
[type="submit"].minus:before,
[type="reset"].minus:before {
  content: 'h';
}
.button.print:before,
.link.print:before,
[type="submit"].print:before,
[type="reset"].print:before,
.button.print-view:before,
.link.print-view:before,
[type="submit"].print-view:before,
[type="reset"].print-view:before {
  content: 'P';
  font-size: 20px;
}
.button.toggle-info:before,
.link.toggle-info:before,
[type="submit"].toggle-info:before,
[type="reset"].toggle-info:before,
.button.expand:before,
.link.expand:before,
[type="submit"].expand:before,
[type="reset"].expand:before {
  content: '=';
}
.button.reset:before,
.link.reset:before,
[type="submit"].reset:before,
[type="reset"].reset:before,
.button[type="reset"]:before,
.link[type="reset"]:before,
[type="submit"][type="reset"]:before,
[type="reset"][type="reset"]:before {
  content: 'i';
}
.link.intern:before {
  background: url('img/ico-arrow-right.svg') no-repeat center center;
}
.link.read:before,
.link.pdf:before {
  background: url('img/ico-read.svg') no-repeat center center;
}
.link.webinar:before {
  content: '';
  background: url('img/ico-webinar.svg') no-repeat center center;
  width: 22px;
  margin-right: 5px;
}
.link.bookmark-add:before {
  background: url('img/ico-bookmark-add.svg') no-repeat center center;
}
.link.bookmark-remove:before {
  background: url('img/ico-bookmark-remove.svg') no-repeat center center;
}
.link.german:before,
.link.english:before {
  content: '';
  width: 25px;
  height: 24px;
}
.link.german:before {
  background: url('img/icon-flagge-sprache-deutsch.svg') no-repeat center center;
}
.link.english:before {
  background: url('img/icon-flagge-sprache-englisch.svg') no-repeat center center;
}
.link.darkgray.read:before {
  background: url('img/ico-read-darkgray.svg') no-repeat center center;
}
.link.white.read:before {
  background: url('img/ico-read-white.svg') no-repeat center center;
}
.button.intern:before {
  background: url('img/ico-arrow-right-white.svg') no-repeat center center;
}
.button.read:before,
.button.pdf:before {
  background: url('img/ico-read-white.svg') no-repeat center center;
}
.button.bookmark-add:before {
  background: url('img/ico-bookmark-add-white.svg') no-repeat center center;
}
.button.bookmark-remove:before {
  background: url('img/ico-bookmark-remove-white.svg') no-repeat center center;
}
.area-color-lightblue .button,
.area-color-lightblue [type="submit"] {
  background-color: #62B8E8;
  color: #ffffff;
}
.area-color-lightblue .button:hover,
.area-color-lightblue [type="submit"]:hover,
.area-color-lightblue .button:focus,
.area-color-lightblue [type="submit"]:focus {
  background-color: #539cc5;
  color: #ffffff;
}
.area-color-teal .button,
.area-color-teal [type="submit"] {
  background-color: #668483;
  color: #ffffff;
}
.area-color-teal .button:hover,
.area-color-teal [type="submit"]:hover,
.area-color-teal .button:focus,
.area-color-teal [type="submit"]:focus {
  background-color: #94a9a8;
  color: #ffffff;
}
.area-color-turquoise .button,
.area-color-turquoise [type="submit"] {
  background-color: #38B5AA;
  color: #ffffff;
}
.area-color-turquoise .button:hover,
.area-color-turquoise [type="submit"]:hover,
.area-color-turquoise .button:focus,
.area-color-turquoise [type="submit"]:focus {
  background-color: #74cbc4;
  color: #ffffff;
}
a:not(.link).pdf {
  display: block;
  font-family: 'Din Pro', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  transition: color 0.2s;
}
a:not(.link).pdf:hover,
a:not(.link).pdf:focus {
  color: #66b3d7;
}
a:not(.link).pdf:before {
  background: url('img/ico-read.svg') no-repeat center center;
  background-size: contain;
  color: inherit;
  content: '';
  display: inline-block;
  font-family: 'Iconfont' !important;
  height: 24px;
  line-height: 24px;
  margin-right: 8px;
  vertical-align: top;
  width: 15px;
}
@media screen and (max-width: 479px) {
  .button,
  [type="submit"] {
    width: 100%;
    display: block;
  }
   + .button,
   + [type="submit"] {
    margin-top: 10px;
  }
}
